import { apiRequest } from '../index';

// const SUBSCRIBERS_API = process.env.REACT_APP_SUBSCRIBERS_API;
const tempApi = 'https://api.staging.trustalice.com';
export async function createTransaction(body) {
  try {
    const response = await apiRequest(`${tempApi}/transactions/`, 'POST', body);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function sendSms(body) {
  try {
    const response = await apiRequest(`${tempApi}/transactions/sendsms`, 'POST', body);
    return response;
  } catch (error) {
    throw error;
  }
}
