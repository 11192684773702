import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@mui/material';

const useStyles = makeStyles(theme => ({
  score: {
    justifyContent: 'center',
    alignContent: 'center',
    // minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '500',
    paddingLeft: '30px',
  },
  successText: {
    display: 'flex',
    paddingLeft: '30px',
    // justifyContent: 'space-around',
    textAlign: 'center',
    // verticalAlign: 'middle',
  },
  number: {
    // position: 'absolute',
    // top: '50%',
    // left: 0,
    // right: 0,
    // bottom: '50%',
    // justifyContent: 'center',
    // alignItems: 'center',
    color: '#0080b7',
    // fontWeight: '500',
  },
  circle: {
    display: 'flex',
    width: '100px',
    height: '100px',
    backgroundColor: 'green',
    borderRadius: '50%',
  },
  grid: {
    paddingTop: '20px',
    // paddingBottom: '20px',
  },
}));


function VerifyMessageDialog({
  handleChange, message, errors, results,
}) {
  const classes = useStyles();
  return (
    <React.Fragment>
      { !results ?
          <React.Fragment>
        <Typography>Enter the message you want to test below</Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="verify message"
              name="verify message"
              data-cy="verify message"
              label={"verify message"}
              value={message}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              fullWidth
              required
            >
            </TextField>
          </Grid>
        </Grid>
        </React.Fragment>
        :
        <React.Fragment>

          <Grid container spacing={1}>
          <Grid container spacing={1} direction='column' className={classes.grid}>

          <Grid item xs={12} md={3} lg={3}>
          <Typography>Trustalice Score:</Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
          <Typography
            variant='h3'
            // align='center'
            // alignCenter
            className={classes.number}
          >
            {results.trustLevel}
          </Typography>
          </Grid>
          </Grid>
          <Divider fullWidth/>
          <Grid container spacing={1} direction='column' className={classes.grid}>
          { 
            (results.trustDescription.length > 0) &&
            <Grid item xs={12}>
              <Typography>Score Description:</Typography>
            </Grid>
          }
          <Grid item xs={12}>
          {
            results.trustDescription.map((desc, index) => (
              <Grid item xs={12}>
              <Typography
                className={classes.number}
              >
                  {desc}
              </Typography>
            </Grid>
            ))
          }
          </Grid>
          </Grid>
          </Grid>
        </React.Fragment>
      }
    </React.Fragment>
  );
}



export default VerifyMessageDialog;
