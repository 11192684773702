import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DemoPage from './DemoPage';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './themes/Material-UI';
import awsconfig from './aws-exports';


Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const useStyles = makeStyles(theme => ({
  container: {
  width: '100%',
  height: '100%',
  },
}));



function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
    <div className={classes.container}>
    <DemoPage>
    </DemoPage>
    </div>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
