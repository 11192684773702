import Auth from '@aws-amplify/auth';

const logger = require('pino')({ name: 'userAuth', level: process.env.logLevel || 'info' });

async function getIdToken() {
  try {
    const session = await Auth.currentSession();
    return session.idToken.jwtToken;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

async function getIdTokenPayload() {
  try {
    const session = await Auth.currentSession();
    return session.idToken;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

async function getUserId() {
  try {
    const session = await Auth.currentSession();
    return session.idToken.payload.sub;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

export {
  getIdToken,
  getIdTokenPayload,
  getUserId,
};
