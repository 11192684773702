import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(() => ({
  paper: {
    paddingTop: `env(safe-area-inset-top)`,
    paddingBottom: `env(safe-area-inset-bottom)`,
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResponsiveDialog({
  open, handleClose, handleSubmit, disableEnforceFocus,
  submitText, dialogTitle, isFullScreen,
  isSubmitDisabled, children, style, fullScreenDisabled = false,
  maxWidth, fullWidth, dialogContentClassName, TransitionComponent, className
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Dialog
      disableEnforceFocus={disableEnforceFocus}
      fullScreen={isFullScreen || (fullScreen && !fullScreenDisabled)}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby={dialogTitle}
      TransitionComponent={TransitionComponent}
      classes={{
        paper: classes.paper
      }}
      style={{
        ...style,
      }}
      className={className}
    >
      {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      <DialogContent
        className={dialogContentClassName}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <Button data-cy="close-dialog" onClick={handleClose} color="primary">
          Close
        </Button>
        {
          handleSubmit &&
          <Button
            color="primary"
            variant="contained"
            data-cy="submit-dialog"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {submitText ? submitText : 'Submit'}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

ResponsiveDialog.defaultProps = {
  fullWidth: true,
  maxWidth: 'sm'
}

export function ResponsiveDialogSlideUp(props) {
  return (
    <ResponsiveDialog
      TransitionComponent={Transition}
      {...props}
    />
  );
}