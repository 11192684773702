import { apiRequest } from '../index';

const filterObjectErrors = (response) => {
  response.trustDescription.forEach((desc, index) => {
    if(typeof desc === 'object') {
      response.trustDescription[index] = 'ENOTFOUND'
    }
  })
  // remove from individual url errors as well
  // response.items.forEach((item) => {
  //   const result = item.urlTrustDescription.filter(desc => typeof desc !== 'object');
  //   item.urlTrustDescription = result;
  // })
  return response;
}

// const SUBSCRIBERS_API = process.env.REACT_APP_SUBSCRIBERS_API;
const tempApi = 'https://api.staging.trustalice.com/protection';
export default async function verifyMessage(body) {
  try {
    const response = await apiRequest(`${tempApi}`, 'POST', body);
    const formattedResponse = filterObjectErrors(response);
    return formattedResponse;
  } catch (error) {
    throw error;
  }
}
