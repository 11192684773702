import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Collapse, Button, Grid, Divider, FormHelperText, Tooltip,
  IconButton, Slider,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import InfoIcon from '@mui/icons-material/Info';
import AddFileImage from '../../utils/add-file.svg';
import ImagePlaceholder from '../../utils/image-placeholder.svg';
import { formatFileSize, shortenString, formatFileTypes } from '../../utils/format';
import { getCroppedImg } from '../../utils/canvasUtils';
import ResponsiveDialog from '../../Components/ResponsiveDialog/ResponsiveDialog';
import { getBase64ImageFromUrl } from '../../utils';


const getColor = (props, theme) => {
  if (props.isDragAccept) {
    return theme.palette.success.main;
  }
  if (props.isDragReject) {
    return theme.palette.error.main;
  }
  if (props.isDragActive) {
    return theme.palette.primary.main;
  }
  return theme.palette.grey[300];
};

const getCropColor = (props,theme) => {
    return props.cropBackground ? 'black' : 'white'
}

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: 10,
    borderColor: (props) => getColor(props, theme),
    borderStyle: 'dashed',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
    },
  },
  selectFileImage: {
    width: 70,
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '2px',
    borderRadius: 5,
    borderColor: (props) => getColor(props, theme),
    // borderStyle: 'dashed',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  thumbContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginBottom: theme.spacing(3),
    marginTop: 300,
  },
  container: {
    width: '90%',
  },
  imageGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  imageDescriptions: {

  },
  imgPreview: {
    maxWidth: 100,
    maxHeight: 200,
    borderRadius: 5,
    alignItems: 'center',
  },
  fileSizeExceededWrapper: {
    marginTop: theme.spacing(2),
  },
  removeFileButton: {
    maxWidth: 150,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    color: theme.palette.error.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  root: {
    marginTop: "16px",
    marginBottom: "8px"
  },
  buttons: {
    display: "flex",
  },
  button: {
    display: "flex",
    justifyContent: 'flex-start',
    margin: `0px ${theme.spacing(0.5)}`
  },
  input: {
    display: 'none',
  },
  imageIcon: {
    fontSize: 30,
    color: 'rgb(150, 150, 150)',
    marginRight: theme.spacing()
  },
  cropContainer: {
    marginTop: 5,
    position: 'relative',
    width: '100%',
    height: 200,
    backgroundColor: props => getCropColor(props,theme),
    [theme.breakpoints.up('sm')]: {
      height: 350,
    },
  },
  changeBackgroundButton: {
    marginTop: 0,
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },

}));

function FileUploader({
  multiple, name, handleChange, limit, handleFileSizeChange, onCrop, values,
  helperText, requirementsText, error, handleFileValidity, validFileTypes,
}) {
  const [files, setFiles] = useState([]);
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [isCropComplete, setIsCropComplete] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imageSrc, setImageSrc] = useState([]);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropBackground, setCropBackground] = useState(false);



  const calculateTotalSize = (fileList = []) => fileList.reduce((accumulator, item) => accumulator + item.size, 0);

  const handleCloseCroppingDialog = () => {
    setIsCropComplete(true);
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    try {
      setCroppedAreaPixels(croppedAreaPixels)

      const croppedImage = await getCroppedImg(
        imageSrc[0].preview,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const onDrop = useCallback((submittedFiles) => {
    if (submittedFiles) {
      handleChange({
        target: {
          name,
          value: submittedFiles,
        },
      });
      const totalSize = calculateTotalSize(submittedFiles);
      setTotalFilesSize(totalSize);
      handleFileSizeChange(totalSize);
    }
    setFiles(submittedFiles.map((f) => Object.assign(f, {
      preview: URL.createObjectURL(f),
    })));
    setImageSrc(submittedFiles.map((f) => Object.assign(f, {
      preview: URL.createObjectURL(f),
    })));
  }, [handleChange, handleFileSizeChange, name]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple,
    onDrop,
  });

  const classes = useStyles({
    isDragActive, isDragAccept, isDragReject, cropBackground,
  });

  const removeFile = (index) => {
    const fileList = files;
    fileList.splice(index, 1);
    handleChange({
      target: {
        name,
        value: fileList,
      },
    });
    handleFileValidity(false);

    const totalSize = calculateTotalSize(fileList);
    setTotalFilesSize(totalSize);
    handleFileSizeChange(totalSize);
    setFiles([...fileList]);
    setImageSrc([...fileList]);
    resetCrop();
  };
  const resetCrop = () => {
    setCroppedImage(null);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setCroppedAreaPixels(null);
  };
  const isFileValid = (fileName, fileTypes) => {
    const result = fileTypes.some((fileType) => (fileName).includes(fileType));
    handleFileValidity(result);
    return result;
  };

  const handleCropComplete = () => {
    setIsCropComplete(true);
    values.file64 = croppedImage
    onClose()
  }

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  const thumbs = files.map((file, i) => {
    const keyId = `${file.name}${i}`;
    return (
      <div className={classes.container} key={keyId}>
        {
          isCropComplete &&
          <React.Fragment>
            <Grid container spacing={1} className={classes.thumbContainer}>
              <Grid item md={4} xs={12} className={classes.imageGrid}>
                <img
                  alt={file.name}
                  src={
                    file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'
                      ? file.preview
                      : ImagePlaceholder
                  }
                  className={classes.imgPreview}
                />
              </Grid>
              <Grid item md={4} xs={12} className={classes.imageDescriptions}>
                <Typography variant={'h6'}>
                  {shortenString(file.path)}
                </Typography>
                <Typography gutterBottom>
                  {file.type}
                </Typography>
                <Typography gutterBottom>
                  {formatFileSize(file.size)}
                </Typography>
                <Button
                  className={classes.removeFileButton}
                  color={'inherit'}
                  variant={'outlined'}
                  onClick={() => removeFile(i)}

                  size={'small'}
                >
                  Remove
                </Button>
                <Button
                  className={classes.removeFileButton}
                  color={'inherit'}
                  variant={'outlined'}
                  onClick={() => setIsCropComplete(false)}
                  size={'small'}
                >
                  Crop
                </Button>
                <span />
              </Grid>
            </Grid>
            <Collapse in={totalFilesSize > limit}>
              <div className={classes.fileSizeExceededWrapper}>
                <Typography color={'error'}>
                  {`File(s) exceed size limit of ${formatFileSize(limit)}. Please choose smaller file(s)`}
                </Typography>
              </div>
            </Collapse>
            <Collapse in={!isFileValid(file.name, validFileTypes)}>
              <div >
                <Typography color={'error'}>
                  {`File not of the correct type. Accepted file types include: ${formatFileTypes(validFileTypes)}`}
                </Typography>
              </div>
            </Collapse>
            {
              files.length > i + 1 && <Divider />
            }
          </React.Fragment>
        }

        {
          !isCropComplete &&
          <React.Fragment>
            <ResponsiveDialog
              open={!isCropComplete}
              handleClose={handleCloseCroppingDialog}
              dialogTitle={"Crop Image"}
              maxWidth="lg"
              handleSubmit={handleCropComplete}
              submitText={"crop"}
            >
              <Button
              className={classes.changeBackgroundButton}
              color={'inherit'}
              variant={'outlined'}
              onClick={() => setCropBackground(!cropBackground)}
              size={'small'}
            >
              Change background

              </Button>
              <div className={classes.cropContainer}>
                <Cropper
                  image={imageSrc[0].preview}
                  cropShape='round'
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={1}
                  restrictPosition={false}
                  cropSize={{width:250, height:250}}
                  onCropChange={setCrop}
                  showGrid={false}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className={classes.controls}>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >{
                      "zoom"
                    }
                  </Typography>
                  <Slider
                    value={zoom}
                    min={0.01}
                    max={2}
                    step={0.05}
                    aria-labelledby="Zoom"
                    classes={{ root: classes.slider }}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    {
                      "rotate"
                    }
                  </Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    classes={{ root: classes.slider }}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                </div>
              </div>
            </ResponsiveDialog>
          </React.Fragment>
        }
      </div>
    );
  });

  useEffect(
    () => () => (
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    ),
    [files],
  );

  useEffect(() => {
    if (values.files[0] && !values.file64) {
      getBase64ImageFromUrl(values.files[0])
        .then((result) => {
          console.log('base64 is ',result)
          values.file64=result
        })
        .catch(err => console.error(err));
    }
  }, [values]);

  return (
    <>
      {
        files.length === 0 && (
          <div {...getRootProps({ className: classes.dropzoneContainer })}>
            <input {...getInputProps()} data-cy="fileUploadInput" />
            <div>
              <img
                className={classes.selectFileImage}
                alt={multiple ? 'Select Files' : 'Select File'}
                src={AddFileImage}
              />
            </div>
            <div>
              <Typography component="p" variant="body1" >
                {`Upload Company Logo`}
              </Typography>
            </div>
            <Tooltip title={requirementsText}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
      {
        files.length > 0 && (
          <div className={classes.thumbsContainer}>
            {thumbs}
          </div>
        )
      }
      <FormHelperText
        className={error && classes.errorText}
      >
        {helperText || error}
      </FormHelperText>
    </>
  );
}

FileUploader.defaultProps = {
  multiple: false,
  requirementsText: <div>Accepted file formats:
    <ul>
      <li>.png</li>
      <li>.jpg</li>
      <li>.jpeg</li>
    </ul>
  </div>,


};

FileUploader.propTypes = {
  values: PropTypes.object,
  multiple: PropTypes.bool,
  handleFileSizeChange: PropTypes.func,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  limit: PropTypes.number,
  helperText: PropTypes.string,
  requirementsText: PropTypes.string,
  onCrop: PropTypes.func,
  error: PropTypes.string,
  handleFileValidity: PropTypes.func,
  validFileTypes: PropTypes.array,
};

export default FileUploader;

