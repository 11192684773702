import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const primary = {
  light: '#000000',
  main: '#00a9e1',
  dark: '#0080b7',
  contrastText: '#fff',
};

const secondary = {
  light: '#000000',
  main: '#000000',
  dark: '#00a9e1',
  contrastText: '#000000',
};

export const theme = {
  palette: {
    type: 'light',
    primary,
    secondary,
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    danger: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: secondary.light,
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 14,
    textTransform: 'none',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '25px',
        paddingLeft: '24px',
        paddingRight: '24px',
        fontWeight: 600,
      },
      contrastText: '#000000',
    },
  },
};

const muiTheme = createMuiTheme(theme);
export default muiTheme;
