import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Typography, Grid } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveDialog from './Components/ResponsiveDialog';
import VerifyMessage from './Components/VerifyMessage/VerifyMessage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';
import CreateTransaction from './Components/CreateTransaction/CreateTransaction';
import CreateCompany from './Components/CreateCompany/CreateCompany';
import { createTransaction, sendSms } from './utils/api/transactions';
import verifyMessage from './utils/api/protection';
import Logo from './utils/TrustAlice Icon.svg';
import { getCompanies, createCompany } from './utils/api/company';

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'center',
  },
  successText: {
    display: 'flex',
    paddingRight: '10px',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  successIcon: {
    paddingLeft: '10px',
  },
  button: {
    width: '15vw',
  },
  logo: {
    height: '50vh',
    width: 'auto',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'center',
    paddingBottom: '10vh'
  },
  loadingDiv: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: 'fixed',
  },
  demoText: {
    padding: '5vw',
  }
}));

function DemoPage() {
  const classes = useStyles();
  const [createCompanyError, setCreateCompanyError] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [transactionOpen, setTransactionOpen] = useState(false);
  const [companyFormOpen, setCompanyFormOpen] = useState(false);
  const [protectionOpen, setProtectionOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [transactionResponse, setTransactionResponse] = useState(false);
  const [companyResponse, setCompanyResponse] = useState(false);
  const [results, setResults] = useState(false);
  const [secretCode, setSecretCode] = useState();
  const [creatingCompany, setCreatingCompany] = useState(false);
  const [contactMethod, setContactMethod] = useState();
  const [transactionDetails, setTransactionDetails] = useState({
    company: '',
    agentId: '',
    method: '',
    details: '',
    subject: '',
    customerEmail: '',
    customerPhone: '',
    id: '',
    companyId: '',
    isChecked: false,
  })
  useEffect(() => {
    const handleGetCompanies = async () => {
      try {
        setLoading(true);
        const response = await getCompanies();
        const sortedCompanies = response.Items;
        sortedCompanies.sort((a, b) => a.name.localeCompare(b.name))

        setCompanies(sortedCompanies);
        setLoading(false);
        console.log('responsefromgetcomp', sortedCompanies);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };
    handleGetCompanies();
  }, [])

  const isTransactionReady = (transactionDetails.company && transactionDetails.method &&
    transactionDetails.details && transactionDetails.subject && (transactionDetails.customerPhone || transactionDetails.customerEmail))

  const updateTransactionDetails = async (newValue, target, companyId) => {
    const newObj = {
      ...transactionDetails
    }
    newObj[target] = newValue;
    setTransactionDetails(newObj)
    console.log('transactionDetails', transactionDetails);
  }

  const resetState = () => {
    setMessage('');
    setResults('');
    setTransactionResponse(false);
    setTransactionDetails({
      company: '',
      agentId: '',
      method: '',
      details: '',
      subject: '',
      customerPhone: '',
      customerEmail: '',
      id: '',
      companyId: '',
      isChecked: false,
    })
  }

  const handleCloseCompanyConfirmation = () => {
    setCompanyResponse(null)
  }

  const updateVerifyMessageText = (event) => {
    setMessage(event.target.value)
  }
  const handleCloseTransaction = () => {
    setTransactionOpen(false);
    setTransactionDetails({
      company: '',
      agentId: '',
      method: '',
      details: '',
      subject: '',
      customerPhone: '',
      customerEmail: '',
      id: '',
      companyId: '',
      isChecked: false,
    });
    setTransactionResponse(false);
  }
  const handleCloseCompanyForm = () => {
    setCompanyFormOpen(false);
  }
  const handleSubmitCompany = async (values) => {
    setCreatingCompany(true);
    try {
      if (values.file64) {
        console.log('is 64')
        const companyDetails = {
          logo: values.file64,
          name: values.companyName,
          subscriptionTier: values.subscriptionTier,
          tierExpiry: values.tierExpiry,
          domains: values.domains,
          countryCode: values.countryCode,
          companyURL: values.companyURL,
          companyPhone: values.companyPhone,
          companyAddress: values.companyAddress,
          companyFacebook: values.companyFacebook,
          companyTwitter: values.companyTwitter,
          companyInstagram: values.companyInstagram,
          gLogoMid: values.gLogoMid,
          logoIdentifier: values.logoIdentifier,
        }
        const createCompanyResponse = await createCompany(companyDetails);
        setCompanyResponse(createCompanyResponse);
        console.log(createCompanyResponse);
        setCompanyFormOpen(false);

      }
    }
    catch (err) {
      setCreateCompanyError(true);
      setCreatingCompany(false);
      console.error(err);
    }


  }
  const handleSubmitTransaction = async () => {
    try {
      const index = companies.findIndex(x => x.name === transactionDetails.company);
      const selectedComp = companies[index];
      console.log('selectedComp', selectedComp);
      console.log('Transaction Submitted', transactionDetails);
      setLoading(true);
      const response = await createTransaction({
        ...transactionDetails,
        companyId: selectedComp.id
      })
      if (transactionDetails.isChecked) {
        await sendSms({ number: transactionDetails.customerPhone, details: 'Welcome To Trust Alice' })
      }
      setTransactionResponse(response);
      console.log('response1', response);
      setSecretCode(response.Item.secretCode);
      setContactMethod(response.Item.method);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }
  const handleCloseProtection = () => {
    console.log('Protection Dialog Closed');
    setProtectionOpen(false);
    resetState();

  }

  const handleSubmitProtection = async () => {
    try {
      setLoading(true);
      const response = await verifyMessage({
        message
      })
      setResults(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }
  const transactionTitle = 'Create Notification'
  const companyFormTitle = 'Register Company'
  const protectionTitle = 'Verify Protection'

  return (
    <div className={classes.container}>
      <div className={classes.demoText} >
        <Typography style={{ fontWeight: 600 }} variant='h3'>DEMO SITE</Typography>
      </div>
      <div className={classes.logoContainer}>
        <img className={classes.logo} alt='Logo' src={Logo} />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          style={{ backgroundColor: '#29b2e7' }}
          className={classes.button}
          variant='contained'
          disabled={!companies}
          onClick={() => setTransactionOpen(true)}
        >
          Create Notification
        </Button>
        <Button
          style={{ backgroundColor: '#29b2e7' }}
          className={classes.button}
          variant='contained'
          onClick={() => setCompanyFormOpen(true)}
        >
          Create Company
        </Button>
        <Button
          style={{ backgroundColor: '#29b2e7' }}
          className={classes.button}
          variant='contained'
          onClick={() => setProtectionOpen(true)}
        >
          Verify Message
        </Button>
      </div>
      {
        transactionOpen &&
        <ResponsiveDialog
          open={transactionOpen}
          disableEnforceFocus={true}
          handleClose={handleCloseTransaction}
          handleSubmit={!transactionResponse && handleSubmitTransaction}
          isSubmitDisabled={!isTransactionReady}
          submitText={"Create"}
          dialogTitle={transactionTitle}
        >
          <CreateTransaction
            handleChange={updateTransactionDetails}
            values={transactionDetails}
            companies={companies}
            employees={employees}
            results={transactionResponse}
            code={secretCode}
            methods={contactMethod}
          >
          </CreateTransaction>
        </ResponsiveDialog>
      }
      {
        companyFormOpen &&

        <CreateCompany
          companyFormOpen={companyFormOpen}
          handleCloseCompanyForm={handleCloseCompanyForm}
          companyFormTitle={companyFormTitle}
          loading={creatingCompany}
          onSubmit={handleSubmitCompany}
          results={companyResponse}
        >
        </CreateCompany>
      }
      {
        protectionOpen &&
        <ResponsiveDialog
          disableEnforceFocus={true}
          open={protectionOpen}
          handleClose={handleCloseProtection}
          isSubmitDisabled={!message || loading}
          handleSubmit={!results && handleSubmitProtection}
          submitText={"Verify"}
          dialogTitle={protectionTitle}
        >
          <VerifyMessage
            handleChange={updateVerifyMessageText}
            value={message}
            results={results}
          >
          </VerifyMessage>
        </ResponsiveDialog>
      }
      {
        companyResponse &&
        <ResponsiveDialog
          disableEnforceFocus={companyResponse}
          open={companyResponse}
          handleClose={handleCloseCompanyConfirmation}
          dialogTitle={companyFormTitle}
        >
          <div className={classes.successText}>
            {
              !createCompanyError ?
                <Grid item xs={12}>
                  <Typography
                    align='left'
                    className={classes.successText}
                  >
                    Company Registered Successfully
                  </Typography>
                  <CheckCircleIcon
                    className={classes.successIcon}
                    sx={{ color: green[500] }}
                    fontSize='large' />
                </Grid>
                :
                <Grid item xs={12}>
                  <Typography
                    align='left'
                    className={classes.successText}
                  >
                    Error Registering Company
                  </Typography>
                  <CancelIcon
                    className={classes.successIcon}
                    sx={{ color: red[500] }}
                    fontSize='large' />
                </Grid>
            }
          </div>
        </ResponsiveDialog>

      }
      {loading &&
        <div className={classes.loadingDiv}>
          <CircularProgress size={60} color="primary" />
        </div>
      }
    </div>
  );
}

export default DemoPage;
