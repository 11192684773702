import React, { useState } from 'react';
import { Grid, Typography, TextField, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ResponsiveDialog from '../ResponsiveDialog';
import { makeStyles } from '@material-ui/core/styles';
import FileUploader from '../FileUploader/FileUploader';
import { tierOptions } from '../../utils/constants';



const useStyles = makeStyles(() => ({
    shell: {
        color: '#000',
        borderColor: '#00a9e1',
    },
    select: {
        marginBottom: '20px',
      },
    description: {
        padding: `5vh`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

function CreateCompanyForm({ values, errors, touched, handleChange, handleFileSizeChange,
    fileSizeLimit, onCrop, results
}) {
    const handleFileValidity = () => {
        return true;
    };
    const classes = useStyles();
    const validFileTypes = ['.jpg', '.png', '.jpeg',];
    return (
        <div className={classes.shell}>
            <React.Fragment>
                <Typography className={classes.description}>Register a company by filling in the form below</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="companyName"
                            name="companyName"
                            data-cy="companyName"
                            label={"Company Name"}
                            value={values.companyName}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            error={errors.companyName && touched.companyName}
                            helperText={errors.companyName && touched.companyName
                                ? errors.companyName : ''}
                            required
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-tier">Subscription Tier*</InputLabel>
                            <Select
                                className={classes.select}
                                input={<OutlinedInput label="Subscription Tier" />}
                                id="subscription tier"
                                name="subscription tier"
                                value={values.subscriptionTier}
                                fullWidth
                                onChange={handleChange}
                            >
                                {tierOptions.map(c => (
                                    <MenuItem
                                        value={c}
                                    >
                                        {c}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="countryCode"
                            name="countryCode"
                            data-cy="countryCode"
                            label={"Country Code (Example: IE)"}
                            value={values.countryCode}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            error={errors.countryCode && touched.countryCode}
                            helperText={errors.countryCode && touched.countryCode
                                ? errors.countryCode : ''}
                            required
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="tierExpiry"
                            name="tierExpiry"
                            data-cy="tierExpiry"
                            label={"Tier Expiry"}
                            value={values.tierExpiry}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.tierExpiry && touched.tierExpiry}
                            helperText={errors.tierExpiry && touched.tierExpiry
                                ? errors.tierExpiry : ''}
                            fullWidth
                            required
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="domains"
                            name="domains"
                            data-cy="domains"
                            label={"Domains (Example: bankofireland.com,boi.ie,boi.com)"}
                            value={values.domains}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.domains && touched.domains}
                            helperText={errors.domains && touched.domains
                                ? errors.domains : ''}
                            fullWidth
                            required
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="companyURL"
                            name="companyURL"
                            data-cy="companyURL"
                            label={"Company URL"}
                            value={values.companyURL}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.companyURL && touched.companyURL}
                            helperText={errors.companyURL && touched.companyURL
                                ? errors.companyURL : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="companyPhone"
                            name="companyPhone"
                            data-cy="companyPhone"
                            label={"Company Phone"}
                            value={values.companyPhone}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.companyPhone && touched.companyPhone}
                            helperText={errors.companyPhone && touched.companyPhone
                                ? "Must be a number" : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="companyAddress"
                            name="companyAddress"
                            data-cy="companyAddress"
                            label={"Company Address"}
                            value={values.companyAddress}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.companyAddress && touched.companyAddress}
                            helperText={errors.companyAddress && touched.companyAddress
                                ? errors.companyAddress : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="companyTwitter"
                            name="companyTwitter"
                            data-cy="companyTwitter"
                            label={"Company Twitter"}
                            value={values.companyTwitter}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.companyTwitter && touched.companyTwitter}
                            helperText={errors.companyTwitter && touched.companyTwitter
                                ? errors.companyTwitter : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="companyInstagram"
                            name="companyInstagram"
                            data-cy="companyInstagram"
                            label={"Company Instagram"}
                            value={values.companyInstagram}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.companyInstagram && touched.companyInstagram}
                            helperText={errors.companyInstagram && touched.companyInstagram
                                ? errors.companyInstagram : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="companyFacebook"
                            name="companyFacebook"
                            data-cy="companyFacebook"
                            label={"Company Facebook"}
                            value={values.companyFacebook}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.companyFacebook && touched.companyFacebook}
                            helperText={errors.companyFacebook && touched.companyFacebook
                                ? errors.companyFacebook : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="gLogoMid"
                            name="gLogoMid"
                            data-cy="gLogoMid"
                            label={"gLogoMid"}
                            value={values.gLogoMid}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.gLogoMid && touched.gLogoMid}
                            helperText={errors.gLogoMid && touched.gLogoMid
                                ? errors.gLogoMid : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // select
                            id="logoIdentifier"
                            name="logoIdentifier"
                            data-cy="logoIdentifier"
                            label={"Logo Identifier"}
                            value={values.logoIdentifier}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={errors.logoIdentifier && touched.logoIdentifier}
                            helperText={errors.logoIdentifier && touched.logoIdentifier
                                ? errors.logoIdentifier : ''}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                    <FileUploader
                        name="files"
                        handleFileValidity={handleFileValidity}
                        validFileTypes={validFileTypes}
                        handleChange={handleChange}
                        onCrop={onCrop}
                        handleFileSizeChange={handleFileSizeChange}
                        multiple={false}
                        limit={fileSizeLimit}
                        helperText={errors.files && touched.files
                            ? errors.files : ''}
                        error={errors.files && touched.files}
                        values={values}
                    >
                    </FileUploader>
                    </Grid>
                </Grid>
            </React.Fragment>
        </div>
    );
}
CreateCompanyForm.validationSchema = Yup.object().shape({
    files: Yup.array()
        .min(1, 'Please provide a company logo image')
        .required('Required'),
    file64: Yup.string(),
    companyName: Yup.string()
        .min(1, 'Please provide a company name')
        .required('Please provide a company name'),
    subscriptionTier: Yup.string()
        .min(1, 'Please provide a subscription tier'),
        // .required('Please provide a subscription tier'),
    countryCode: Yup.string()
        .min(1, 'Please provide a country code')
        .required('Please provide a country code'),
    tierExpiry: Yup.string()
        .min(1, 'Please provide a tier expiry date')
        .required('Please provide a tier expiry date'),
    domains: Yup.string()
        .min(1, 'Please provide at least 1 domain')
        .required('Please provide at least 1 domain'),
    companyURL: Yup.string(),
    companyPhone: Yup.number(),
    companyAddress: Yup.string(),
    companyTwitter: Yup.string(),
    companyFacebook: Yup.string(),
    companyInstagram: Yup.string(),
    gLogoMid: Yup.string(),
    logoIdentifier: Yup.string(),
});

export default function CreateCompanyDialog({ initialValues, enableReinitialize, onSubmit, fileSizeLimit, companyFormOpen, handleCloseCompanyForm,
    companyFormTitle, loading, results }) {
    const [totalFileSize, setTotalFileSize] = useState(fileSizeLimit);
    const [currentFileValidity, setFileValidity] = useState(true);
    const isSubmitDisabled = (isSubmitting, dirty, isValid) => {
        if (isSubmitting || !dirty || loading || !isValid
            || totalFileSize > fileSizeLimit || !currentFileValidity) {
            return true;
        }
        else return false;
    }
    
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CreateCompanyForm.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={enableReinitialize}
        >
            {(
                {
                    handleChange, handleSubmit, handleReset,
                    submitForm, isSubmitting,
                    dirty, values, isValid, ...otherProps
                },
            ) => (
                
                <ResponsiveDialog
                    open={companyFormOpen}
                    disableEnforceFocus={true}
                    handleClose={handleCloseCompanyForm}
                    handleSubmit={(e) => handleSubmit(e)}
                    isSubmitDisabled={isSubmitDisabled(isSubmitting, dirty, isValid)}
                    submitText={"Create"}
                    dialogTitle={companyFormTitle}
                    startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    <CreateCompanyForm
                        handleChange={handleChange}
                        values={values}
                        disabled={loading}
                        fileSizeLimit={fileSizeLimit}
                        handleFileValidity={(fileValidity) => setFileValidity(fileValidity)}
                        handleFileSizeChange={(fileSize) => setTotalFileSize(fileSize)}
                        {...otherProps}
                    >
                    </CreateCompanyForm>
                </ResponsiveDialog>
            )}
        </Formik>
    );
}

CreateCompanyForm.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.bool,
    handleChange: PropTypes.func,
    handleFileSizeChange: PropTypes.func,
    fileSizeLimit: PropTypes.number,
    onCrop: PropTypes.func,
}
CreateCompanyDialog.defaultProps = {
    initialValues: {
        files: [],
        companyName: '',
        tierExpiry: '2030-01-01',
    },
};

CreateCompanyDialog.propTypes = {
    initialValues: PropTypes.object,
    enableReinitialize: PropTypes.bool,
    onSubmit: PropTypes.func,
    fileSizeLimit: PropTypes.number,
    companyFormOpen: PropTypes.bool,
    handleCloseCompanyForm: PropTypes.func,
    companyFormTitle: PropTypes.string,
    loading: PropTypes.bool,
}