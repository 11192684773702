import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@mui/material/colors';

import { methodOptions } from '../../utils/constants';


const useStyles = makeStyles(() => ({
  shell: {
    color: '#000',
    borderColor: '#00a9e1',
  },
  description: {
    padding: `5vh`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  select: {
    marginBottom: '20px',
  },
  successText: {
    display: 'flex',
    paddingRight: '10px',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  successIcon: {
    paddingLeft: '10px',
  },
  checkBox: {
    paddingTop: '2vh',
    paddingLeft: '20vh',
    paddingRight: '20vh',
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

function VerifyMessageDialog({
  handleChange, errors, values, companies, employees, loading, results, code, methods,
}) {
  const classes = useStyles();
  const [showSMS, setShowSMS] = useState(false);
  const secretCode = code;
  const contactMethod = methods;
  const {
    company, method, subject, details, customerPhone, agentId, isChecked,
  } = values;
  useEffect(() => {
    if (contactMethod === 'Phone (SMS)') {
      setShowSMS(true);
    }
  }, []);
  return (
    <div className={classes.shell}>
      {
        !results ?
          <React.Fragment>
            <Typography className={classes.description}>Notify your customer of an incoming notification by filling in the form below</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-label">Company*</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    input={<OutlinedInput label="Company" />}
                    className={classes.select}
                    id="company"
                    value={company}
                    placeholder='asdr'
                    fullWidth
                    onChange={(e) => {
                      // console.log('eee', e);
                      const index = companies.findIndex(x => x.name === e.target.value);
                      handleChange(e.target.value, 'company', companies[index].id)
                    }}
                  >
                    {companies.map(c => (
                      <MenuItem
                        value={c.name}
                      >
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-label">Method*</InputLabel>
                  <Select
                    className={classes.select}
                    input={<OutlinedInput label="Method" />}
                    id="method"
                    value={method}
                    fullWidth
                    onChange={(e) => {
                      if (e.target.value === 'Phone (SMS)') {
                        setShowSMS(true);
                      }
                      handleChange(e.target.value, 'method');
                  }}
                  >
                    {methodOptions.map(c => (
                      <MenuItem
                        value={c}
                      >
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // select
                  id="Agent ID"
                  name="Agent ID"
                  data-cy="Agent ID"
                  label={"Agent ID"}
                  value={agentId}
                  onChange={(e) => handleChange(e.target.value, 'agentId')}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                >
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // select
                  id="subject"
                  name="subject"
                  data-cy="subject"
                  label={"Subject"}
                  value={subject}
                  onChange={(e) => handleChange(e.target.value, 'subject')}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                >
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // select
                  id="details"
                  name="details"
                  data-cy="details"
                  label={"Details"}
                  value={details}
                  onChange={(e) => handleChange(e.target.value, 'details')}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                >
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // select
                  id="customerPhone"
                  name="customerPhone"
                  data-cy="customerPhone"
                  label={"Customer Phone (e.g +353855555555)"}
                  value={customerPhone}
                  onChange={(e) => handleChange(e.target.value, 'customerPhone')}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                >
                </TextField>
              </Grid>
              {
                showSMS &&
                <div className={classes.checkBox}>
                <FormControlLabel control={<Checkbox className={classes.checkbox} checked={isChecked}
                onChange={() => handleChange(!isChecked, 'isChecked')}/>} label="Tick to send an SMS" />
                </div>
              }
            </Grid>
          </React.Fragment>
          :
          <div className={classes.successText}>
            <Grid item xs={12}>
              <Typography
                align='left'
                className={classes.successText}
              >
                Notification Created Successfully
              </Typography>
              <Typography
                align='left'
                className={classes.successText}
              >
                Secret Code: {secretCode}
              </Typography>
              <CheckCircleIcon
                className={classes.successIcon}
                sx={{ color: green[500] }}
                fontSize='large' />
            </Grid>
          </div>
      }
    </div>
  );
}

export default VerifyMessageDialog;
