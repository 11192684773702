import { apiRequest } from '../index';

// const SUBSCRIBERS_API = process.env.REACT_APP_SUBSCRIBERS_API;
const tempApi = 'https://api.staging.trustalice.com';

export async function getCompanies(body) {
  try {
    const response = await apiRequest(`${tempApi}/company/`, 'GET');
    console.log('myresp', response);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function createCompany(body) {
  try {
    const response = await apiRequest(`${tempApi}/company/`, 'POST', body);
    console.log('myresp', response);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCompanyEmployees(companyId) {
  try {
    console.log('companyID selected', companyId);
    const response = await apiRequest(`${tempApi}/company/${companyId}/employees`, 'GET');
    console.log('employeesrespos', response);
    return response;
  } catch (error) {
    throw error;
  }
}