const { DateTime } = require('luxon');

export const formatFileSize = (fileSize) => {
  const fileSizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let value = fileSize;
  for (let i = 0; i < fileSizes.length; i += 1) {
    if (value < 1000) {
      return `${value.toFixed(2)} ${fileSizes[parseInt(i, 10)]}`;
    }
    value /= 1000;
  }
  return '';
};

export const formatFileTypes = (fileTypes) => {
  const result = fileTypes.join(', ');
  return result;
};

export const shortenString = (string, maxLength = 15) => {
  if (string.length > maxLength) {
    return `${string.substr(0, maxLength)}...`;
  }

  return string;
};

export function getStatusStep(currentStatus) {
  const pipelineStatuses = ['pending', 'input', 'predict', 'output', 'complete'];

  return pipelineStatuses.indexOf(currentStatus);
}

export function ISOtoLocale(timestamp, length = 'DATETIME_MEDIUM', invalidString = 'Unknown') {
  return (!DateTime.fromISO(timestamp).invalid)
    ? DateTime.fromISO(timestamp).toLocaleString(DateTime[`${length}`]).toString()
    : invalidString;
}
export function ISOtoLocaleRelative(timestamp, invalidString = 'Unknown') {
  return (!DateTime.fromISO(timestamp).invalid)
    ? DateTime.fromISO(timestamp).toRelative()
    : invalidString;
}
