import { getIdToken } from './userAuth';

export const apiRequest = async (url, method, body) => {
    try {
      const token = await getIdToken();
      // console.log('TOKEN:', token);
      const response = await fetch(url,
        {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(body),
        });
        // console.log('INDEXRESP', response);
      if (response.status === 200 || response.status === 201) {
        return response.json();
      }
      throw Error(`${response.status} ${response.statusText}`);
    } catch (error) {
      throw error;
    }
  };

  export async function getBase64ImageFromUrl(file) {
    // const res = await fetch(imageUrl);
    // const blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(file);
    })
  }
  
  
  export default apiRequest;
  